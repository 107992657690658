import * as React from 'react';
import loadable from '@loadable/component';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FormTypes, sectionList } from '../../constants/queryFragments';
import { graphql, navigate } from 'gatsby';
import Cookies from 'universal-cookie';
import Header from '../../common/header/header';
import Footer from '../../common/footer/footer';
import GlobalStyles from '../../styles/globalStyles';
import BackToTopScroll from '../../components/backToTopScroll/backToTopScroll';
import { Helmet } from 'react-helmet';

const BlogPageTemplate = ({ data }) => {
  const cookies = new Cookies();
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    setLoaded(true);
  }, []);
  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const languageFilteredData = data?.allContentfulSparlings?.edges.find(
    (edge) =>
      cookies.get('language')
        ? edge?.node?.node_locale === cookies.get('language')
        : edge?.node?.node_locale === 'en-US'
  );
  const sectionsArray = languageFilteredData?.node?.sections;
  React.useEffect(() => {
    !sectionsArray && navigate('/content-unavailable');
  }, [sectionsArray]);

  return !loaded ? (
    <>
      <Helmet
        htmlAttributes={{
          lang: languageFilteredData?.node?.node_locale || 'en',
        }}
      >
        <title>
          {languageFilteredData?.node?.title
            ? languageFilteredData?.node?.title
            : 'Energy Solutions for Residential and Businesses | Sparlings Propane'}
        </title>
        {languageFilteredData?.node?.metadata && (
          <meta
            name="description"
            content={languageFilteredData?.node?.metadata}
          ></meta>
        )}
      </Helmet>
      <GlobalStyles />

      <Header />
      <>
        {sectionsArray &&
          sectionsArray.map((section, index) => {
            if (
              section?.type &&
              sectionList.includes(section?.type) &&
              !FormTypes?.includes(section?.type)
            ) {
              const SectionComponent = loadable(() =>
                import(`../../components/${section.type}/${section.type}`)
              );
              return <SectionComponent key={index} sectionData={section} />;
            }
            return <></>;
          })}
      </>
      <BackToTopScroll />
      <Footer />
    </>
  ) : (
    <>
      <Helmet
        htmlAttributes={{
          lang: languageFilteredData?.node?.node_locale || 'en',
        }}
      >
        <title>
          {languageFilteredData?.node?.title ??
            'Energy Solutions for Residential and Businesses | Sparlings Propane'}
        </title>
        {languageFilteredData?.node?.metadata && (
          <meta
            name="description"
            content={languageFilteredData?.node?.metadata}
          ></meta>
        )}
      </Helmet>
      <GlobalStyles />

      <Header />
      <>
        {sectionsArray &&
          sectionsArray.map((section, index) => {
            if (section?.type && sectionList.includes(section?.type)) {
              const SectionComponent = loadable(() =>
                import(`../../components/${section.type}/${section.type}`)
              );
              return <SectionComponent key={index} sectionData={section} />;
            }
            return <></>;
          })}
      </>
      <BackToTopScroll />
      <Footer />
    </>
  );
};

export default BlogPageTemplate;

export const pageQuery = graphql`
  query allBlogPagesQuery(
    $pageIdentifier: String!
    $contentfulContent: Boolean!
    $contentfulTextWithImage: Boolean!
    $contentfulContentList: Boolean!
    $contentfulListImagesWithPath: Boolean!
  ) {
    allContentfulSparlings(
      filter: { identifier: { type: { eq: $pageIdentifier } } }
    ) {
      edges {
        node {
          name
          identifier {
            type
          }
          slug
          metadata
          title
          node_locale
          sections {
            ...TextWithImage @include(if: $contentfulTextWithImage)
            ...PageContent @include(if: $contentfulContent)
            ...ContentList @include(if: $contentfulContentList)
            ...ImagesWithPathList @include(if: $contentfulListImagesWithPath)
          }
        }
      }
    }
  }
`;
